import { COLORS, TYPOGRAPHY } from '@app/constants';
import { BreakpointsEnum, TabData } from '@app/types';
import { useAppDispatch } from '@app/store/hooks';
import { actions } from '@app/store';

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Tabs from '@app/ui/components/Tabs';

import { PhoneForm } from './PhoneForm';
import { EmailForm } from './EmailForm';

enum LoginTabs {
  phone = 'phone',
  email = 'email',
}

const tabData: TabData[] = [
  {
    text: 'По номеру телефона',
    value: LoginTabs.phone,
  },
  {
    text: 'По электронной почте',
    value: LoginTabs.email,
  },
];

const ChooseWay: FC = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(LoginTabs.phone);

  const goToRegisterPage = () => {
    dispatch(actions.auth.setModalActiveTab('register'));
  };

  return (
    <Wrapper>
      <LoginTitle>Войти</LoginTitle>
      <NotAccount>
        Еще нет аккаунта?
        <StyledNavLink onClick={goToRegisterPage}>
          Зарегистрироваться
        </StyledNavLink>
      </NotAccount>
      <StyledTabs
        data={tabData}
        activeValue={activeTab}
        onToggle={setActiveTab}
      />
      <div>
        {activeTab === LoginTabs.phone && <PhoneForm />}
        {activeTab === LoginTabs.email && <EmailForm />}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (min-width: ${BreakpointsEnum.s}px) {
    padding: 40px 32px 48px 32px;
  }
  @media (max-width: ${BreakpointsEnum.s}px) {
    padding: 24px 16px 40px 16px;
  }
`;

const LoginTitle = styled.h2`
  ${TYPOGRAPHY.heading3Bold24};
  color: ${COLORS.grayscale700};
  margin-bottom: 8px;
`;

const NotAccount = styled.div`
  ${TYPOGRAPHY.body1Regular14};
  color: ${COLORS.grayscale600};
  a {
    font-weight: bold;
    color: ${COLORS.primary300};
    margin-left: 4px;
  }

  @media (min-width: ${BreakpointsEnum.s}px) {
    margin-bottom: 40px;
  }
  @media (max-width: ${BreakpointsEnum.s}px) {
    margin-bottom: 32px;
  }
`;

const StyledTabs = styled(Tabs)`
  @media (min-width: ${BreakpointsEnum.s}px) {
    margin-bottom: 32px;
  }

  @media (max-width: ${BreakpointsEnum.s}px) {
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: fit-content;
    button {
      display: flex;
      width: max-content;
      padding-left: 0;
      padding-right: 0;
      &:not(:last-child) {
        margin-right: 17px;
      }
    }
  }
`;

const StyledNavLink = styled.div`
  display: inline-block;
  ${TYPOGRAPHY.body1Regular14}
  font-weight: 600;
  cursor: pointer;
  color: ${COLORS.primary300};
  margin-left: 5px;

  &:hover {
    color: ${COLORS.primary400};
  }
`;

export default ChooseWay;
