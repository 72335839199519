import { COLORS, TYPOGRAPHY } from '@app/constants';

import styled from 'styled-components';
import { BreakpointsEnum } from '@app/types';
import TextInput from '@app/ui/components/TextInput';
import Button from '@app/ui/components/Button';
import PasswordInput from '@app/ui/components/PasswordInput';
import NavLink from '@app/ui/components/NavLink';

export const StyledInput = styled(TextInput)`
  width: 100%;
`;

export const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${BreakpointsEnum.s}px) {
    margin-top: 28px;
  }
  @media (max-width: ${BreakpointsEnum.s}px) {
    margin-top: 24px;
  }
`;

export const StyledPasswordInput = styled(PasswordInput)`
  margin-bottom: 24px;
`;

export const ForgotPasswordLink = styled(NavLink)`
  ${TYPOGRAPHY.body1Regular14};
  font-weight: bold;
  color: ${COLORS.primary300};
  &[data-current='false'] {
    color: ${COLORS.primary300};
  }
  &:hover {
    color: ${COLORS.primary400};
  }
  @media (max-width: ${BreakpointsEnum.s}px) {
    margin-bottom: 32px;
  }
`;
