import { COLORS, TYPOGRAPHY } from '@app/constants';
import { TabData } from '@app/types';
import React, { FC } from 'react';
import styled from 'styled-components';

interface TabsProps {
  data: TabData[];
  onToggle: React.Dispatch<React.SetStateAction<any>>;
  activeValue: string;
  className?: string;
}

const Tabs: FC<TabsProps> = ({ data, activeValue, onToggle, className }) => {
  return (
    <Root className={className}>
      {data.map((tab, index) => (
        <Tab
          type="button"
          key={index}
          $isActive={activeValue === tab.value}
          onClick={() => onToggle(tab.value)}
        >
          {tab.text}
        </Tab>
      ))}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
`;

const Tab = styled.button<{ $isActive: boolean }>`
  ${TYPOGRAPHY.body1Regular14};
  font-weight: ${({ $isActive }) => $isActive && '500'};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: ${({ $isActive }) =>
    $isActive ? COLORS.grayscale700 : COLORS.grayscale400};
  text-align: center;
  margin-right: 8px;
  width: 100%;
  &:last-child {
    margin-right: 0;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${({ $isActive }) =>
      $isActive ? COLORS.primary300 : 'transparent'};
    border-radius: 4px 4px 0px 0px;
  }
`;

export default Tabs;
