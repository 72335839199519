import { ChooseWay, EnterCode, EnterPassword } from './components';
import { ChooseWayModal } from './components/ChooseWay';

export enum SignInSteps {
  CHOOSE_WAY = 'choose_way',
  ENTER_CODE = 'enter_code',
  ENTER_PASSWORD = 'enter_password',
}

export const loginSteps = [
  {
    label: SignInSteps.CHOOSE_WAY,
    component: <ChooseWay />,
  },
  {
    label: SignInSteps.ENTER_CODE,
    component: <EnterCode />,
  },
  {
    label: SignInSteps.ENTER_PASSWORD,
    component: <EnterPassword />,
  },
];

export const loginStepsModal = [
  {
    label: SignInSteps.CHOOSE_WAY,
    component: <ChooseWayModal />,
  },
  {
    label: SignInSteps.ENTER_CODE,
    component: <EnterCode />,
  },
  {
    label: SignInSteps.ENTER_PASSWORD,
    component: <EnterPassword />,
  },
];
