import { StepperProvider, useStepper } from '@app/components/Stepper';
import { compose } from '@app/helpers/compose';
import { withLayout } from '@app/hoc/withLayout';
import withPublic from '@app/hoc/withPublic';
import { Login } from '@app/pageComponents/LoginPage';
import { loginSteps } from '@app/pageComponents/LoginPage/Login/common';

import { LayoutEnum } from '@app/types';
import { NextSeo } from 'next-seo';

export enum SignInSteps {
  CHOOSE_WAY = 'choose_way',
  ENTER_CODE = 'enter_code',
  ENTER_PASSWORD = 'enter_password',
}

function LoginPage() {
  const stepper = useStepper({
    steps: loginSteps,
    defaultStep: SignInSteps.CHOOSE_WAY,
  });

  return (
    <>
      <NextSeo title="Вход" />
      <StepperProvider {...stepper}>
        <Login />
      </StepperProvider>
    </>
  );
}

export default compose(withPublic, withLayout(LayoutEnum.Auth))(LoginPage);
