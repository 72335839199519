import { COLORS, TYPOGRAPHY } from '@app/constants';
import { BreakpointsEnum, CustomError } from '@app/types';

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import CodeInputField from '@app/components/CodeInputField';
import CountdownWithRepeat from '@app/components/CountdownWithRepeat';
import Button from '@app/ui/components/Button';
import FlatBtn from '@app/ui/components/FlatBtn';
import { useStepperDispatcher } from '@app/components/Stepper';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { selectors, actions } from '@app/store';
import {
  useSignInPhoneMutation,
  useSignInPhoneSendVerificationMutation,
} from '@app/store/api/auth';
import { toastService } from '@app/services/toastService';
import { SignInSteps } from '@/pages/login';

const EnterCode: FC = () => {
  const dispatch = useAppDispatch();
  const { switchStep } = useStepperDispatcher();
  const signInContext = useAppSelector(selectors.auth.selectSignUpContext);
  const [signIn, { isLoading: isSignInLoading }] = useSignInPhoneMutation();
  const [sendCode, { isLoading: isSendCodeLoading }] =
    useSignInPhoneSendVerificationMutation();

  const [codeValue, setCodeValue] = useState('');
  const [codeErrorMessage, setCodeErrorMessage] = useState<
    string | undefined
  >();
  const [seconds, setSeconds] = useState<number | undefined>(
    signInContext.secondsToSendNextVerificationCode,
  );

  const handleSignIn = async () => {
    try {
      await signIn({
        code: codeValue,
        phone: signInContext.value,
      }).unwrap();

      if (signInContext.mode === 'modal') {
        dispatch(actions.auth.setAuthModalState(false));
        toastService.success({ title: 'Вход выполнен' });
      }
    } catch (err) {
      const error = err as CustomError;
      setCodeErrorMessage(error.data.message);
    }
  };

  const handleRepeatCodeSend = async () => {
    try {
      const response = await sendCode({ phone: signInContext.value }).unwrap();
      setSeconds(response.secondsBeforeNextAttempt);
    } catch (err) {
      const error = err as CustomError;
      toastService.error({ title: 'Ошибка ', content: error.data.message });
    }
  };

  const handleChangePhone = () => {
    switchStep(SignInSteps.CHOOSE_WAY);
  };

  const handleSignInWithPassword = () => {
    switchStep(SignInSteps.ENTER_PASSWORD);
  };

  return (
    <Wrapper>
      <Title>Введите код</Title>
      <Subtitle>
        Мы отправили вам код по СМС на номер {signInContext.value}
      </Subtitle>
      <StyledFlatBtn styleType="primary" onClick={handleChangePhone}>
        Изменить номер
      </StyledFlatBtn>
      <CodeInput errorMessage={codeErrorMessage} onChange={setCodeValue} />
      <StyledCountdownWithRepeat
        onRepeat={handleRepeatCodeSend}
        seconds={seconds}
      />
      {signInContext.hasPassword && (
        <NoCodeBtn styleType="primary" onClick={handleSignInWithPassword}>
          Войти с паролем
        </NoCodeBtn>
      )}
      <StyledButton
        styleType="primary"
        isLarge
        isLoading={isSignInLoading || isSendCodeLoading}
        onClick={handleSignIn}
        isDisabled={codeValue.length < 6}
      >
        Войти
      </StyledButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (min-width: ${BreakpointsEnum.s}px) {
    padding: 40px 32px 48px 32px;
  }
  @media (max-width: ${BreakpointsEnum.s}px) {
    padding: 24px 16px 40px 16px;
  }
`;

const Title = styled.p`
  ${TYPOGRAPHY.heading3Bold24};
  color: ${COLORS.grayscale700};
  margin-bottom: 16px;
`;

const Subtitle = styled.p`
  ${TYPOGRAPHY.body1Regular14};
  color: ${COLORS.grayscale600};
  margin-bottom: 12px;
  span {
    color: ${COLORS.primary300};
  }
`;

const StyledFlatBtn = styled(FlatBtn)`
  ${TYPOGRAPHY.body1Medium14}
  margin-bottom: 40px;
  padding-left: 0;
  padding-right: 0;
  @media (max-width: ${BreakpointsEnum.s}px) {
    margin-bottom: 32px;
  }
`;

const CodeInput = styled(CodeInputField)`
  margin-bottom: 40px;
  @media (max-width: ${BreakpointsEnum.s}px) {
    margin-bottom: 32px;
  }
`;

const StyledCountdownWithRepeat = styled(CountdownWithRepeat)`
  @media (max-width: ${BreakpointsEnum.s}px) {
    margin-bottom: 34px;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
  width: 100%;
`;

const NoCodeBtn = styled(FlatBtn)`
  ${TYPOGRAPHY.body1Medium14}
  padding-left: 0;
  padding-right: 0;
`;

export default EnterCode;
