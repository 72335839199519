// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { getInputState } from '@app/utils/getInputState';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ERROR_TEXTS } from '@app/constants';
import { StyledButton, StyledInput, StyledPasswordInput } from '../styles';
import { CustomError } from '@app/types';
import { useSignInMutation } from '@app/store/api/auth';

interface FormData {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup.string().email(ERROR_TEXTS.email).required(ERROR_TEXTS.required),
    password: yup.string().required(ERROR_TEXTS.required),
  })
  .required();

const EmailForm: FC = () => {
  const [signIn, { isLoading }] = useSignInMutation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isValid, dirtyFields, touchedFields },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [isEmailError, setIsEmailError] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await signIn(data).unwrap();
    } catch (err) {
      const error = err as CustomError;

      setIsEmailError(true);
      setError('email', { type: 'onChange' });
      setError('password', { type: 'onChange', message: error.data.message });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div>
        <StyledInput
          type="email"
          label="Адрес электронной почты"
          placeholder="example@gmail.com"
          inputState={getInputState(dirtyFields, errors, 'email')}
          textError={touchedFields.email ? errors.email?.message : ''}
          isError={
            (!!errors.email?.message && touchedFields.email) || isEmailError
          }
          {...register('email')}
          onChange={(e) => {
            setIsEmailError(false);
            register('email').onChange(e);
          }}
        />
        <StyledPasswordInput
          label="Пароль"
          textError={errors.password?.message}
          isError={!!errors.password?.message}
          {...register('password')}
        />
        <StyledButton
          styleType="primary"
          isLoading={isLoading}
          isLarge
          isDisabled={!isDirty || !isValid}
        >
          Войти
        </StyledButton>
      </div>
    </form>
  );
};

export default EmailForm;
