// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { getInputState } from '@app/utils/getInputState';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ERROR_TEXTS } from '@app/constants';
import { StyledButton, StyledInput } from '../styles';
import { useCheckEmailMutation } from '@app/store/api/auth';
import { useAppDispatch } from '@app/store/hooks';
import { useStepperDispatcher } from '@app/components/Stepper';
import { actions } from '@app/store';
import { toastService } from '@app/services/toastService';
import { SignInSteps } from '@/pages/login';

interface FormData {
  email: string;
}

const schema = yup
  .object({
    email: yup.string().email(ERROR_TEXTS.email).required(ERROR_TEXTS.required),
  })
  .required();

export const EmailForm: FC = () => {
  const dispatch = useAppDispatch();
  const { switchStep } = useStepperDispatcher();
  const [checkEmail, { isLoading: isCheckEmailLoading }] =
    useCheckEmailMutation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isValid, dirtyFields, touchedFields },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [isEmailError, setIsEmailError] = useState(false);

  const isEmailExists = async (email: string): Promise<boolean> => {
    const response = await checkEmail({ email }).unwrap();

    return !!response.isAvailable;
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (await isEmailExists(data.email)) {
        setError('email', { type: 'onChange' });
        return toastService.error({
          title: 'Ошибка',
          content: 'Адрес электронной почты не найден',
        });
      }

      dispatch(
        actions.auth.setSignUpContext({
          type: 'email',
          value: data.email,
        }),
      );

      switchStep(SignInSteps.ENTER_PASSWORD);
    } catch (err) {
      setIsEmailError(true);
      setError('email', { type: 'onChange' });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div>
        <StyledInput
          type="email"
          label="Адрес электронной почты"
          placeholder="example@gmail.com"
          inputState={getInputState(dirtyFields, errors, 'email')}
          textError={touchedFields.email ? errors.email?.message : ''}
          isError={
            (Boolean(errors.email?.message) && touchedFields.email) ||
            isEmailError
          }
          {...register('email')}
          onChange={(e) => {
            setIsEmailError(false);
            register('email').onChange(e);
          }}
        />
        <StyledButton
          styleType="primary"
          isLoading={isCheckEmailLoading}
          isLarge
          isDisabled={!isDirty || !isValid}
        >
          Войти
        </StyledButton>
      </div>
    </form>
  );
};
