import { UserSignUpDtoRoleEnum } from '@app/api';
import { useHasMounted } from '@app/hooks/useHasMounted';
import { ROUTES } from '@app/routers';
import { selectors } from '@app/store';
import { useAppSelector } from '@app/store/hooks';
import { isEmptyObject } from '@app/utils/isEmptyObject';
import LoadingState from '@app/ui/components/LoadingState';
import { NextComponentType, NextPageContext } from 'next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { COLORS } from '@app/constants';
import { BaseContext } from 'next/dist/shared/lib/utils';

function withPublic<C extends BaseContext = NextPageContext, IP = {}, P = {}>(
  Component: NextComponentType<C, IP, P>,
) {
  const Auth = (props: P) => {
    const router = useRouter();

    const user = useAppSelector(selectors.auth.selectUser);
    const metadata = useAppSelector(selectors.auth.selectMetadata);

    const hasMounted = useHasMounted();

    if (!hasMounted) {
      return (
        <Root>
          <LoadingState />
        </Root>
      );
    }

    if (user?.id) {
      if (user.role === UserSignUpDtoRoleEnum.Therapist) {
        router.push(ROUTES['/therapist/consultations']);
      }
      if (user.role === UserSignUpDtoRoleEnum.Patient) {
        if (router.query?.availabilityId) {
          const currentUrl = new URL(location.href);
          const paymentUrl = new URL(location.origin + ROUTES['/session-payment'] + currentUrl.search);

          router.push(paymentUrl);
        } else {
          router.push(ROUTES['/client/consultations']);
        }

      }
      if (user.role === UserSignUpDtoRoleEnum.Company) {
        router.push(ROUTES['/for-business']);
      }

      return null;
    }

    if (router.pathname === ROUTES['/app']) {
      router.push(ROUTES['/login']);
      return null;
    }

    if (isEmptyObject(user) || isEmptyObject(metadata)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <Component {...props} />;
    }

    return null;
  };

  // Copy getInitial props so it will run as well
  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps;
  }

  return Auth;
}

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.grayscaleWhite};
`;

export default withPublic;
