import { FC } from 'react';
import { useStepperConsumer } from '@app/components/Stepper';

const LoginModalPage: FC = () => {
  const { activeComponent } = useStepperConsumer();

  return <div>{activeComponent}</div>;
};

export default LoginModalPage;
