import { FC } from 'react';
import styled from 'styled-components';
import Container from '@app/components/Container';
import { useStepperConsumer } from '@app/components/Stepper';
import { COLORS } from '@app/constants';
import loginBackground from '@app/assets/images/client-auth-background.svg';
import { BreakpointsEnum } from '@app/types';

const Login: FC = () => {
  const { activeComponent } = useStepperConsumer();

  return (
    <Root>
      <StyledContainer>
        <Form>{activeComponent}</Form>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  height: calc(100vh - 58px);
  min-height: 600px;
  background: url(${loginBackground.src}) no-repeat center;
  background-size: cover;
`;

const StyledContainer = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (min-width: ${BreakpointsEnum.s}px) {
    justify-content: flex-end;
  }
  @media (max-width: ${BreakpointsEnum.s}px) {
    padding: 0px;
    justify-content: space-around;
  }
`;

const Form = styled.div`
  background-color: ${COLORS.grayscaleWhite};
  box-shadow: 0px 12px 80px rgba(86, 118, 159, 0.08);
  border-radius: 16px;
  width: 100%;
  max-width: 440px;
`;

export default Login;
