import { getInputState } from '@app/utils/getInputState';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ERROR_TEXTS } from '@app/constants';
import {
  ForgotPasswordLink,
  StyledButton,
  StyledPasswordInput,
} from '../styles';
import { useSignInMutation } from '@app/store/api/auth';
import { CustomError } from '@app/types';
import PhoneInput from '@app/ui/components/PhoneInput';
import { isValidPhoneNumber } from 'react-phone-number-input';

interface FormData {
  phone: string;
  password: string;
}

const schema = yup
  .object({
    phone: yup
      .string()
      .required(ERROR_TEXTS.required)
      .nullable()
      .test('phone', ERROR_TEXTS.phoneFormat, (val) => {
        if (!!val) {
          return isValidPhoneNumber(val);
        }
        return true;
      }),
    password: yup.string().required(ERROR_TEXTS.required),
  })
  .required();

const PhoneForm: FC = () => {
  const [signIn, { isLoading }] = useSignInMutation();

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await signIn(data).unwrap();
    } catch (err) {
      const error = err as CustomError;

      setError('phone', { type: 'onChange' });
      setError('password', { type: 'onChange', message: error.data.message });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div>
        <PhoneInput
          name="phone"
          control={control}
          label="Номер телефона"
          placeholder="Телефон"
          textError={touchedFields.phone ? errors.phone?.message : ''}
          isError={Boolean(errors.phone?.message) && touchedFields.phone}
          inputState={getInputState(touchedFields, errors, 'phone')}
          isValid={isValid}
        />
        <StyledPasswordInput
          label="Пароль"
          textError={errors.password?.message}
          isError={Boolean(errors.password?.message)}
          {...register('password')}
        />
        <ForgotPasswordLink to="/password-recovery">
          Забыли пароль?
        </ForgotPasswordLink>
        <StyledButton
          styleType="primary"
          isLoading={isLoading}
          isLarge
          isDisabled={!isDirty || !isValid}
        >
          Войти
        </StyledButton>
      </div>
    </form>
  );
};

export default PhoneForm;
