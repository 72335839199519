import { COLORS, ERROR_TEXTS, TYPOGRAPHY } from '@app/constants';
import { BreakpointsEnum, CustomError } from '@app/types';

import React, { FC } from 'react';
import styled from 'styled-components';
import Button from '@app/ui/components/Button';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { selectors, actions } from '@app/store';
import { useSignInMutation } from '@app/store/api/auth';
import { toastService } from '@app/services/toastService';
import { ForgotPasswordLink, StyledPasswordInput } from '../styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

interface FormData {
  password: string;
}

const schema = yup
  .object({
    password: yup.string().required(ERROR_TEXTS.required),
  })
  .required();

const EnterPassword: FC = () => {
  const signInContext = useAppSelector(selectors.auth.selectSignUpContext);
  const [signIn, { isLoading: isSignInLoading }] = useSignInMutation();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSignIn = handleSubmit(async (data) => {
    try {
      await signIn({
        [signInContext.type]: signInContext.value,
        password: data.password,
      }).unwrap();

      if (signInContext.mode === 'modal') {
        dispatch(actions.auth.setAuthModalState(false));
        toastService.success({ title: 'Вход выполнен' });
      }
    } catch (err) {
      const error = err as CustomError;
      toastService.error({ title: 'Ошибка', content: error.data.message });
    }
  });

  return (
    <form onSubmit={handleSignIn}>
      <Wrapper>
        <Title>Введите пароль</Title>
        <StyledPasswordInput
          label="Пароль"
          textError={errors.password?.message}
          isError={Boolean(errors.password?.message)}
          {...register('password')}
        />
        <ForgotPasswordLink shouldShowCurrent={false} to="/password-recovery">
          Забыли пароль?
        </ForgotPasswordLink>
        <StyledButton
          styleType="primary"
          isLarge
          isLoading={isSignInLoading}
          onClick={handleSignIn}
          isDisabled={false}
        >
          Войти
        </StyledButton>
      </Wrapper>
    </form>
  );
};

const Form = styled.div`
  background-color: ${COLORS.grayscaleWhite};
  box-shadow: 0px 12px 80px rgba(86, 118, 159, 0.08);
  border-radius: 16px;
  width: 100%;
  max-width: 440px;
`;

const Wrapper = styled.div`
  @media (min-width: ${BreakpointsEnum.s}px) {
    padding: 40px 32px 48px 32px;
  }
  @media (max-width: ${BreakpointsEnum.s}px) {
    padding: 24px 16px 40px 16px;
  }
`;

const Title = styled.p`
  ${TYPOGRAPHY.heading3Bold24};
  color: ${COLORS.grayscale700};
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
  width: 100%;
`;

export default EnterPassword;
