import { getInputState } from '@app/utils/getInputState';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ERROR_TEXTS } from '@app/constants';
import { StyledButton } from '../styles';
import PhoneInput from '@app/ui/components/PhoneInput';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useAppDispatch } from '@app/store/hooks';
import { useStepperDispatcher } from '@app/components/Stepper';
import { actions } from '@app/store';
import {
  useCheckPhoneMutation,
  useSignInPhoneSendVerificationMutation,
} from '@app/store/api/auth';
import { CustomError } from '@app/types';
import { toastService } from '@app/services/toastService';
import { SignInSteps } from '@/pages/login';

interface FormData {
  phone: string;
}

const schema = yup
  .object({
    phone: yup
      .string()
      .required(ERROR_TEXTS.required)
      .nullable()
      .test('phone', ERROR_TEXTS.phoneFormat, (val) => {
        if (!!val) {
          return isValidPhoneNumber(val);
        }
        return true;
      }),
  })
  .required();

export const PhoneForm: FC = () => {
  const dispatch = useAppDispatch();
  const { switchStep } = useStepperDispatcher();
  const [sendCode, { isLoading: isSendCodeLoading }] =
    useSignInPhoneSendVerificationMutation();
  const [checkPhone, { isLoading: isCheckPhoneLoading }] =
    useCheckPhoneMutation();

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const isPhoneExists = async (phone: string): Promise<boolean> => {
    const response = await checkPhone({ phone }).unwrap();

    actions.auth.setSignUpContext({
      hasPassword: response.hasPassword,
    });

    return !!response.isAvailable;
  };

  const sendCodeVerification = async (phone: string) => {
    try {
      const response = await sendCode({ phone }).unwrap();

      dispatch(
        actions.auth.setSignUpContext({
          type: 'phone',
          value: phone,
          secondsToSendNextVerificationCode: response.secondsBeforeNextAttempt,
        }),
      );
    } catch (err) {
      const error = err as CustomError;

      if (error.data.data.secondsBeforeNextAttempt) {
        dispatch(
          actions.auth.setSignUpContext({
            type: 'phone',
            secondsToSendNextVerificationCode: error.data.data
              .secondsBeforeNextAttempt as number,
          }),
        );

        toastService.error({
          title: 'Превышение лимита',
          content: error.data.message,
        });
      }
    } finally {
      switchStep(SignInSteps.ENTER_CODE);
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    if (await isPhoneExists(data.phone)) {
      setError('phone', { type: 'onChange' });
      return toastService.error({
        title: 'Ошибка',
        content: 'Телефон не найден',
      });
    }

    await sendCodeVerification(data.phone);
  });

  return (
    <form onSubmit={onSubmit}>
      <div>
        <PhoneInput
          name="phone"
          control={control}
          label="Номер телефона"
          placeholder="Телефон"
          textError={touchedFields.phone ? errors.phone?.message : ''}
          isError={Boolean(errors.phone?.message) && touchedFields.phone}
          inputState={getInputState(touchedFields, errors, 'phone')}
          isValid={isValid}
        />
        <StyledButton
          styleType="primary"
          isLarge
          isDisabled={!isDirty || !isValid}
          isLoading={isSendCodeLoading || isCheckPhoneLoading}
        >
          Далее
        </StyledButton>
      </div>
    </form>
  );
};
